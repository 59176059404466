import { API_STATUS, CsvFilename } from "../../../../constants/commonConstants";
import { useState } from "react";
import {
  OrderType,
  tabsOptions,
} from "../../constants/FeePaymentStatusConstant";
import { Box, Grid } from "@mui/material";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";

import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import DocumentManagement from "../../../documentManagement/managers/documentManager";
import { toast } from "react-toastify";
import CommitmentManagementManager from "../../managers/commitmentManagementManager";
import { getFormattedDate } from "../../../../utils/dateUtils";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Commitment Payment Status",
    navigationButton: false,
  },
};

export default function FeePaymentStatus() {
  const userData = useContext(AuthContext);
  const documentManagementManager = new DocumentManagement(userData);
  const commitmentManager = new CommitmentManagementManager();

  const [selectedTab, setSelectedTab] = useState(tabsOptions[0].value);
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const [selectedStartup, setSelectedStartup] = useState<any>({});
  const [startupOptions, setStartupOptions] = useState<any>([]);

  const handleSearchStartup = async (value: string) => {
    try {
      let result = await documentManagementManager.searchDealName(value);
      result = result?.data.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setStartupOptions(result);
    } catch (error) {
      console.log(error);
    }
  };

  const [downloading, setDownloading] = useState(false);

  const handleDownloadDocument = async () => {
    try {
      setDownloading(true);
      const result: any = await commitmentManager.downloadCsvDocument(
        page,
        selectedStartup?.value ?? "",
        selectedTab,
        "transaction_fee"
      );
      var a = document.createElement("a");
      a.href = "data:attachment/csv;charset=utf-8," + encodeURI(result.data);
      a.target = "_blank";
      a.download = `${CsvFilename.TRANSACTION_FEE}.csv`;
      document.body.appendChild(a);
      a.click();
      setDownloading(false);
    } catch (e: any) {
      toast.error(e);
      setDownloading(false);
    }
  };

  const viewFile = (url: string) => {
    window.open(url, "_blank");
  };

  let columns = [
    {
      field: "id",
      headerName: "Id",
      flex: 2,
      minWidth: 50,
    },
    {
      field: "user_id",
      headerName: "User Id",
      flex: 2,
      minWidth: 100,
    },

    {
      field: "startup_name",
      headerName: "Startup Name",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "profile_name",
      headerName: "Profile Name",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Investor Email",
      flex: 2,
      minWidth: 350,
    },
    {
      field: "order_type",
      headerName: "Order Type",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "created_date",
      headerName: "Created Date",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      flex: 2,
      minWidth: 100,
    },

    {
      field: "last_updated_date",
      headerName: "Last Updated Date",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "invoice",
      headerName: "View Invoice",
      flex: 2,
      minWidth: 200,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <ActionButton
            onClick={() => viewFile(params.row.transaction_fee_invoice)}
          >
            View Invoice
          </ActionButton>
        );
      },
    },
  ];

  let pendingColumns = [...columns.slice(0, columns.length - 1)];
  pendingColumns.splice(pendingColumns.length - 2, 0, {
    field: "payment_link",
    headerName: "Payment Link",
    flex: 2,
    minWidth: 300,
    renderCell: (params: any) => {
      const actionData = params.row.action;
      return (
        <a
          href={params?.row?.payment_link ?? "-"}
          target="_blank"
          rel="noreferrer"
        >
          {params?.row?.payment_link ?? "-"}
        </a>
      );
    },
  });

  const getColumns = (tab: any) => {
    return tab === "pending" ? pendingColumns : columns;
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const getData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { count, results } = await commitmentManager.transactionPaymentStatus(
        page,
        selectedStartup?.value ?? "",
        selectedTab
      );
      setCount(count);
      let result = results?.map((item: any) => ({
        ...item,
        user_id: item?.investor_informations?.investor?.user?.id,
        startup_name: item?.startups?.name,
        profile_name: item?.investor_informations?.profile_name,
        email: item?.investor_informations?.investor?.user?.email,
        order_type:
          OrderType?.find((it: any) => it?.value === item?.order_type)?.label ??
          "-",
        payment_link: item?.order?.payment_link,
        created_date: getFormattedDate(item?.created_date),
        last_updated_date: getFormattedDate(item?.last_updated_date),
      }));
      setApiStatus(API_STATUS.SUCCESS);

      setFormattedData(result);
    } catch (e: any) {
      toast.error(e);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    getData();
  }, [page, selectedStartup?.value, selectedTab]);

  return (
    <Box p={2}>
      <Grid container spacing={3} mb={"1rem"}>
        <Grid item xs={4}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={8} display="flex" justifyContent="end" gap={2}>
          <Box width="23vw">
            <LvAutoComplete
              label="Select Startup"
              options={startupOptions}
              setOptions={setStartupOptions}
              selectedObject={selectedStartup}
              setSelectedObject={setSelectedStartup}
              onSelect={() => setStartupOptions([])}
              onChange={handleSearchStartup}
            />
          </Box>

          <ActionButton onClick={handleDownloadDocument} disabled={downloading}>
            {downloading ? "Downloading CSV" : "Download CSV"}
          </ActionButton>
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={tabsOptions}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />
      <br />
      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={getColumns(selectedTab)}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
            defaultSortColumn={{ field: "id", sort: "desc" }}
          />
        </>
      )}
    </Box>
  );
}
