import { Box, Grid, Typography } from "@mui/material";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { useState } from "react";
import StartSchemeGeneration from "./StartSchemeGeneration";
import StartupSchemeProgress from "./StartupSchemeProgress";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { breadCrumbDataSchemeDoc } from "../../constants/CADocConstants";

export default function SchemeDocManagement() {
  const tabs: any = [
    {
      name: "Start Scheme Generation",
      value: "start_scheme_generation",
    },
    {
      name: "Startup Scheme Progress",
      value: "start_scheme_progress",
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={5}>
          <Breadcrumb
            steps={breadCrumbDataSchemeDoc?.steps}
            pageHeader={breadCrumbDataSchemeDoc.pageHeader}
            stepKey={breadCrumbDataSchemeDoc.stepKey}
          />
        </Grid>
      </Grid>
      <Typography mb={1.2} textAlign="left">
        *Note: Kindly make sure the deal (of which scheme is about to be
        generated) is added in the Post Commitment Status Management
      </Typography>
      <HorizontalTabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />
      {selectedTab === "start_scheme_generation" ? (
        <StartSchemeGeneration />
      ) : null}
      {selectedTab === "start_scheme_progress" ? (
        <StartupSchemeProgress />
      ) : null}
    </Box>
  );
}
