import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import {
  KycStageOptions,
  KycStatusTabs,
  KycStatusType,
  ReviewStatusOptions,
} from "./KycStatusConstant";
import { API_STATUS } from "../../../../constants/commonConstants";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import UserManagementManager from "../../managers/userManagementManager";
import { Link } from "react-router-dom";
import UpdateReviewStatusModal from "./UpdateReviewStatusModal";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../contexts/authContext";
import { useContext } from "react";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

export default function KycStatus() {
  const userData = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(userData);

  const [selectedInvestor, setSelectedInvestor] = useState<any>({});
  const [investorOptions, setInvestorOptions] = useState<any>([]);

  const [selectedTab, setSelectedTab] = useState(KycStatusTabs[0].value);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const [selected, setSelected] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isOnboarded, setIsOnboarded] = useState<any>();

  const closeModal = () => {
    setOpenModal(false);
  };
  const viewFile = (url: string) => {
    window.open(url, "_blank");
  };

  let columns = [
    {
      field: "name",
      headerName: "Name",

      minWidth: 180,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link
            style={{ textDecoration: "none" }}
            to={`/user/show_user/${params.row?.user_id}/`}
          >
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "lv_review_stage",
      headerName: "LV Review Stage",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "kyc_progress",
      headerName: "Kyc Progress",
      flex: 2,
      minWidth: 220,
    },
    {
      field: "kyc_matches",
      headerName: "KYC Matches",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "ckyc_no",
      headerName: "CKYC No",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "kra_matches",
      headerName: "KRA Matches",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "kra_id",
      headerName: "KRA No",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "onboarding_complete",
      headerName: "Investment Profile Created",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "name_as_per_pan",
      headerName: "Name as per PAN",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "dob",
      headerName: "Date of Birth",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "country_of_citizenship",
      headerName: "Country of Citizenship",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "country_of_residence",
      headerName: "Country of Residence",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "relation_type",
      headerName: "Relation Type",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "relation_name",
      headerName: "Relation Name",
      flex: 2,
      minWidth: 150,
    },

    {
      field: "permanent_address",
      headerName: "Permanent Address",
      flex: 2,
      minWidth: 300,
    },

    {
      field: "correspondence_address",
      headerName: "Correspondence Address",
      flex: 2,
      minWidth: 300,
    },

    {
      field: "bank_account_type",
      headerName: "Bank Account Type",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "bank_account_number",
      headerName: "Bank Account Number",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "ifsc_code",
      headerName: "IFSC/SWIFT code",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "pan_card_file",
      headerName: "PAN Card File",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <OutlinedButton
            disabled={!params.row.pan_card_file}
            onClick={() => viewFile(params.row.pan_card_file)}
          >
            View Pan
          </OutlinedButton>
        );
      },
    },

    {
      field: "address_proof_file",
      headerName: "Address Proof File",
      flex: 2,
      minWidth: 300,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <OutlinedButton
            disabled={!params?.row?.proof_of_address_file}
            onClick={() => viewFile(params?.row?.proof_of_address_file)}
          >
            View Address Proof
          </OutlinedButton>
        );
      },
    },

    {
      field: "cancelled_cheque_file",
      headerName: "Cancelled Cheque File",
      flex: 2,
      minWidth: 300,
      renderCell: (params: any) => {
        const actionData = params.row.action;

        return (
          <OutlinedButton
            disabled={!params?.row?.cancelled_cheque_file}
            onClick={() => viewFile(params?.row?.cancelled_cheque_file)}
          >
            View Cancelled Cheque
          </OutlinedButton>
        );
      },
    },
    {
      field: "comments",
      headerName: "Comment",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 3,
      minWidth: 300,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("action_button", 200),
              borderColor: getColor("action_button", 200),
            }}
            onClick={() => {
              setSelected(params.row);
              setOpenModal(true);
            }}
          >
            Update Review Status
          </OutlinedButton>
        );
      },
    },
  ];

  const getColumns = (tab: any) => {
    return tab === KycStatusType.VERIFIED
      ? columns.filter((item: any) => item.field !== "action")
      : columns;
  };

  const handleInvestorSearch = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData(
        value,
        "only_investor_users"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setInvestorOptions(result);
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let { count, results } = await userManagementManager.getKycStatusData(
        page,
        selectedInvestor?.value ?? "",
        selectedTab,
        isOnboarded?.value ?? ""
      );

      results = results?.map((item: any) => ({
        ...item,
        lv_review_stage:
          ReviewStatusOptions?.find(
            (it: any) => it?.value === item.lv_review_stage
          )?.label ?? "-",
        kyc_matches: item?.kyc_matches ? "Yes" : "No",
        kra_matches: item?.kra_matches ? "Yes" : "No",
        onboarding_complete: item?.onboarding_complete ? "Yes" : "No",
        kyc_progress:
          KycStageOptions?.find((it: any) => it?.value === item.kyc_progress)
            ?.label ?? "-",
      }));

      setFormattedData(results);
      setCount(count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (er: any) {
      setApiStatus(API_STATUS.ERROR);

      toast.error(er);
    }
  };
  useEffect(() => {
    getData();
  }, [page, selectedInvestor?.value, selectedTab, isOnboarded?.value]);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "User Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "KYC Status",
      navigationButton: false,
    },
  };

  return (
    <Box p={2}>
      <UpdateReviewStatusModal
        selected={selected}
        isOpen={openModal}
        handleClose={closeModal}
        reload={getData}
      />
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box width={"40%"}>
            <CoreDropdown
              label="Investment Profile Created"
              options={[
                { label: "Choose Profile Status" },
                {
                  label: "Yes",
                  value: "yes",
                },
                {
                  label: "No",
                  value: "no",
                },
              ]}
              value={isOnboarded}
              onClickMenuItem={(val: any) => {
                setIsOnboarded(val);
              }}
            />
          </Box>
          <Box width="23vw">
            <LvAutoComplete
              label={"Select Investor"}
              options={investorOptions}
              setOptions={setInvestorOptions}
              selectedObject={selectedInvestor}
              setSelectedObject={setSelectedInvestor}
              onSelect={() => setInvestorOptions([])}
              onChange={handleInvestorSearch}
            />
          </Box>
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={KycStatusTabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />
      <br />
      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={getColumns(selectedTab)}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        </>
      )}
    </Box>
  );
}
