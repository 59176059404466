import { API_STATUS } from "../../../../constants/commonConstants";
import DashboardManager from "../../../dashboard/managers/dashboardManager";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { isEmpty } from "../../../../utils/commonUtils";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Link } from "react-router-dom";
import {
  BreadCrumbDataDematInformation,
  DematStatusTabs,
  DematStatusType,
} from "../../constants/dashboardConstants";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";

const columns = [
  {
    field: "id",
    headerName: "S.No",
    align: "center",
    flex: 2,
    minWidth: 120,
  },
  {
    field: "name",
    headerName: "Beneficiary Name",
    headerAlign: "left",
    flex: 2,
    minWidth: 200,
  },
  {
    field: "demate_account_number",
    headerName: "Demat Account number",
    align: "center",
    flex: 2,
    minWidth: 250,
  },
  {
    field: "nominee_name",
    headerName: "Investor Profile",
    headerAlign: "left",
    flex: 2,
    minWidth: 150,
  },

  {
    field: "unlock",
    headerName: "Unlock",
    headerAlign: "left",
    flex: 2,
    minWidth: 150,
  },

  {
    field: "view_profile",
    headerName: "Mark As Verified",
    headerAlign: "left",
    minWidth: 180,
    renderCell: (params: any) => {
      const actionData = params.row.action;
      return (
        <Link
          style={{ textDecoration: "none" }}
          to={`/user/show_user/${params.row?.user_id}/`}
        >
          {params?.row?.name}{" "}
        </Link>
      );
    },
  },
];

export default function DematInformation() {
  const authContext = useContext(AuthContext);
  const dashboardManager = new DashboardManager(authContext);
  const [value, setValue] = useState("");

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [selectedTab, setSelectedTab] = useState(DematStatusTabs[0].value);

  const getColumns = (tab: any) => {
    return tab === DematStatusType.WITH_DEMAT_ACCOUNT
      ? columns.filter((item: any) => item.field !== "action")
      : columns;
  };

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const getData = async (tab: any) => {
    try {
      setApiStatus(API_STATUS.LOADING);
      let data = await dashboardManager.getDematInformation(value, page, tab);
      setFormattedData(data?.formattedData);
      setCount(data?.count);
      setApiStatus(API_STATUS.SUCCESS);
    } catch (error: any) {
      toast.error(error?.message);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  const handleSearchClick = () => {
    getData(selectedTab);
  };

  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
    getData(tab);
  };

  useEffect(() => {
    getData(selectedTab);
  }, [page]);

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={BreadCrumbDataDematInformation?.steps}
            pageHeader={BreadCrumbDataDematInformation.pageHeader}
            stepKey={BreadCrumbDataDematInformation.stepKey}
          />
        </Grid>
        <Grid item xs={9} display="flex" gap={2} justifyContent="end">
          <Box>
            <CustomTextField
              label={"Search by Investor Email"}
              width={"300px"}
              InputLabelProps={{}}
              customStyles={{}}
              error={false}
              value={value}
              onChange={setValue}
              id="name"
              name="name"
            />
          </Box>

          <ActionButton onClick={handleSearchClick}>Search</ActionButton>
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={DematStatusTabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          handleTabChange(tab);
        }}
        scrollable={false}
      />
      <br />
      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="50px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={getColumns(selectedTab)}
            rowHeight={50}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        </>
      )}
    </Box>
  );
}
