import { Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState, useCallback } from "react";
import { RadioButton } from "../../../../ui-library/form-elements/RadioButtonSelect";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import CommitmentManagementManager from "../../managers/commitmentManagementManager";
import CrmTable from "./CrmTable";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Commit Tracker",
    navigationButton: false,
  },
};

const useStyles = makeStyles({
  analyticsContainer: {
    backgroundColor: "rgb(230 230 230)",
    height: "8rem",
    border: "1px solid #dedede",
    width: "100%",
    marginLeft: "1.5rem",
    marginTop: "1.5rem",
    borderRadius: "6px",
  },
  analyticsInnerContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
    "& .MuiTypography-root": {
      fontWeight: 600,
    },
  },
});

const CommitTracker = () => {
  const classes: any = useStyles();
  const tabs: any = [
    {
      name: "Progress",
      value: "progress",
    },
    {
      name: "Completed",
      value: "completed",
    },
  ];
  const [filter, setFilter] = useState<string>("startups");
  const [selectedVal, setSelectedVal] = useState<any>({});
  const [option, setOption] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs[0].value);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const commitmentManagementManager = new CommitmentManagementManager();

  const handleTextChange = useCallback(async () => {
    setLoading(true);
    try {
      let result = await commitmentManagementManager.getCrmList(filter);
      setOption(result?.formattedData);
      setFormattedData([]);
      // setSelectedVal({ label: '', value: null });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [filter]);

  const fetchCrmData = async (value: any) => {
    setLoading(true);
    try {
      let result = await commitmentManagementManager.getCrmData(
        filter,
        selectedTab,
        value
      );
      setFormattedData(result?.formattedData);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleTextChange();
  }, [filter]);

  useEffect(() => {
    fetchCrmData(selectedVal);
  }, [selectedTab]);

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div>
            <Breadcrumb
              steps={breadCrumbData?.steps}
              pageHeader={breadCrumbData.pageHeader}
              stepKey={breadCrumbData.stepKey}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div style={{ marginTop: "30px" }}>
            <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
              Filter Type
            </Typography>
            <RadioButton
              options={[
                { name: "Startup", value: "startups" },
                { name: "Investor", value: "investors" },
              ]}
              iconStyles={{ marginLeft: "4px", color: "red" }}
              value={filter}
              onChange={setFilter}
            />
            <LvAutoComplete
              label={
                filter === "startups" ? "Select Startup" : "Select Investor"
              }
              options={option}
              setOptions={setOption}
              selectedObject={selectedVal}
              setSelectedObject={setSelectedVal}
              onChange={handleTextChange}
              onSelect={(value: any) => {
                fetchCrmData(value);
              }}
            />
          </div>
        </Grid>
        {selectedVal?.value && (
          <>
            <Grid item style={{ width: "100%" }}>
              <div style={{ marginTop: "1rem" }}>
                <HorizontalTabs
                  tabs={tabs}
                  selectedTab={selectedTab}
                  onTabChange={(tab: any) => {
                    // setValue('');
                    setSelectedTab(tab);
                  }}
                  scrollable={false}
                />
              </div>
              {loading ? (
                <TablePlaceholder
                  rows={6}
                  columns={6}
                  rowHeight="40px"
                  columnHeaderHeight="40px"
                />
              ) : (
                <CrmTable
                  formattedData={formattedData}
                  page={page}
                  setPage={setPage}
                  count={count}
                  tab={selectedTab}
                  fetchCrmData={fetchCrmData}
                  selectedVal={selectedVal}
                />
              )}
            </Grid>
            <Grid item className={classes.analyticsContainer}>
              <Box className={classes.analyticsInnerContainer}>
                <Box>
                  <Typography>
                    No of investors: {formattedData.length}
                  </Typography>
                  <Typography>
                    No drawdown sent:{" "}
                    {formattedData.reduce((initial, item) => {
                      item.aif_direct == "aif" &&
                        !!item.ca_doc_status &&
                        !!item.drawdown_sent &&
                        initial++;
                      return initial;
                    }, 0)}
                  </Typography>
                  <Typography>
                    Total amount received:{" "}
                    {formattedData.reduce((initial, item) => {
                      if (
                        (item.aif_direct == "aif" &&
                          !!item.ca_doc_status &&
                          !!item.amount_transfer_status) ||
                        (item.aif_direct == "direct" &&
                          !!item.amount_transfer_status)
                      )
                        initial = initial + Number(item.amount_transfered);
                      return initial;
                    }, 0)}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    No scheme sent:{" "}
                    {formattedData.reduce((initial, item) => {
                      item.aif_direct == "aif" &&
                        !!item.ca_doc_status &&
                        item.scheme_doc_status == "Sent" &&
                        initial++;
                      return initial;
                    }, 0)}
                  </Typography>
                  <Typography>
                    No drawdown accepted:{" "}
                    {formattedData.reduce((initial, item) => {
                      ((item.aif_direct == "aif" &&
                        !!item.ca_doc_status &&
                        !!item.amount_transfer_status) ||
                        (item.aif_direct == "direct" &&
                          !!item.amount_transfer_status)) &&
                        initial++;
                      return initial;
                    }, 0)}
                  </Typography>
                </Box>
                <Box>
                  <Typography>
                    No scheme accepted:{" "}
                    {formattedData.reduce((initial, item) => {
                      item.aif_direct == "aif" &&
                        !!item.ca_doc_status &&
                        !!item.deal_consent_received &&
                        initial++;
                      return initial;
                    }, 0)}
                  </Typography>
                  <Typography>
                    Total committed amount:{" "}
                    {formattedData.reduce(
                      (initial, item) =>
                        initial + Number(item.proposed_amount.amount),
                      0
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              className={classes.analyticsContainer}
              style={{ height: "11rem" }}
            >
              <Box>
                <Typography style={{ fontSize: "1.2rem", fontWeight: 600 }}>
                  **Note:
                </Typography>
                <Typography style={{ fontWeight: 500 }}>
                  To Enable Drawdown:
                </Typography>
                <Typography>
                  1. CA Doc Creation Date should be marked in AIF CRM
                </Typography>
                <Typography>
                  2. Transfer By = Last date to Transfer the funds
                </Typography>
                <Typography>3. Transfer type should be selected</Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default CommitTracker;
