import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import {
  NewCampaignTabs,
  breadCrumbDataEditCampaign,
  breadCrumbDataNewCampaign,
} from "../../../constants/mailCentralConstant/MailCentralConstant";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import { useNavigate, useParams } from "react-router-dom";
import { HorizontalTabs } from "../../../../../ui-library/tabs/horizontal-tabs";
import SelectList from "./SelectList";
import AboutEmail from "./AboutEmail";
import Template from "./Template";
import Confirm from "./Confirm";

type QuizParams = {
  campaign_id: string;
};

const NewCampaign = () => {
  // list id for edit list
  const { campaign_id = "" } = useParams<QuizParams>();
  const breadcrumbDataOnCondition = campaign_id
    ? breadCrumbDataEditCampaign
    : breadCrumbDataNewCampaign;

  const navigate = useNavigate();
  const navigationFunction = () => navigate(-1);
  const [selectedTab, setSelectedTab] = useState(NewCampaignTabs[0].value);

  // select-list state
  const [selectedList, setSelectedList] =
    useState<{ label: string; value: string }[]>();

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadcrumbDataOnCondition?.steps}
            pageHeader={{
              ...breadcrumbDataOnCondition?.pageHeader,
              navigationFunction,
            }}
            stepKey={breadcrumbDataOnCondition?.stepKey}
            breadcrumbSeparator={breadcrumbDataOnCondition?.breadcrumbSeparator}
          />
        </Grid>
      </Grid>
      <br />
      <HorizontalTabs
        tabs={NewCampaignTabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
      />
      {selectedTab === "select_list" && (
        <SelectList
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          setSelectedTab={setSelectedTab}
        />
      )}
      {selectedTab === "about_email" && (
        <AboutEmail setSelectedTab={setSelectedTab} />
      )}
      {selectedTab === "template" && (
        <Template setSelectedTab={setSelectedTab} />
      )}
      {selectedTab === "confirm" && <Confirm />}
    </Box>
  );
};

export default NewCampaign;
