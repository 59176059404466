import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PermissionContext } from "../../contexts/permissionContext";
import LVAdminLogo from "../auth/components/login/lv-admin-logo-white.png";
import { Sidebar } from "../../ui-library/navs/sidebar";
type SideBarProps = {
  onToggle: any;
};

const SideBar = (props: SideBarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUserRoutes = [] } = useContext(PermissionContext);
  const { onToggle } = props;
  const [activeOption, setActiveOption] = useState({
    optionValue: "dashboard",
    subOptionValue: "",
  });

  const options = currentUserRoutes;

  useEffect(() => {
    options?.map((option: any) => {
      if (option.suboptions) {
        option.suboptions.map((suboption: any) => {
          const formattedLink = `/${option.link}/${suboption.link}`;
          if (
            window.location.href.includes(formattedLink) &&
            option.value &&
            suboption.value
          ) {
            setActiveOption({
              optionValue: option.value,
              subOptionValue: suboption.value,
            });
          }
          return 0;
        });
      }
      return 0;
    });
  }, [location.pathname, options]);

  const handleChange = (option: any, suboption: any) => {
    setActiveOption({
      optionValue: option.value,
      subOptionValue: suboption?.value ?? option.value,
    });
  };

  const onClickOnOption = (option: any) => {
    const { suboptions = [], link = "" } = option;
    if (!suboptions.length) {
      navigate(link);
    }
  };

  const onClickOnSubOption = (suboption: any, option: any) => {
    const { link: optionLink = "" } = option;
    const { link: subOptionLink = "" } = suboption;
    const formattedLink = `/${optionLink}/${subOptionLink}`;
    navigate(formattedLink);
  };

  return (
    <>
      <Sidebar
        logo={LVAdminLogo}
        logoSize={{ width: "160px", height: "auto" }}
        onToggle={onToggle}
        options={options}
        onClickOnOption={onClickOnOption}
        onClickOnSubOption={onClickOnSubOption}
        handleChange={handleChange}
        activeOption={activeOption}
      />
    </>
  );
};

export default SideBar;
