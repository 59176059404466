import { makeStyles } from "@mui/styles";
import { getColor } from "../../ui-library/Styles/Colors";

export default makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    margin: "20px 110px 20px 110px",
  },
  description: {
    fontSize: "44px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0px",
    textAlign: "left",
    color: getColor("border", 200),
    marginBottom: "20px",
  },
  subDescription: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0px",
    textAlign: "left",
    color: getColor("extra", 4400),
    marginBottom: "30px",
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  mainBody: {
    minHeight: "95vh",
  },
});
