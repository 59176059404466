import { Divider, Grid } from "@mui/material";
import { Formik } from "formik";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import UserManagementManager from "../../managers/userManagementManager";
import { useRef } from "react";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { Link } from "react-router-dom";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "User Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "User's Panel",
    navigationButton: false,
  },
};

export default function UsersPanel() {
  const authContext = useContext(AuthContext);
  const userManagementManager = new UserManagementManager(authContext);

  const ref1: any = useRef(null);
  const [selectedVal1, setSelectedVal1] = useState<any>({});
  const [option1, setOption1] = useState<any>([]);

  const ref2: any = useRef(null);
  const [selectedVal2, setSelectedVal2] = useState<any>({});
  const [option2, setOption2] = useState<any>([]);

  const [selectedVal3, setSelectedVal3] = useState<any>({});
  const [option3, setOption3] = useState<any>([]);
  const [angelData, setAngelData] = useState([]);

  const handleTextChange1 = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData(value, "users");
      result = result?.results?.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setOption1(result);
    } catch (err: any) {
      toast.error(err?.detail);
    }
  };

  const handleTextChange2 = async (value: any) => {
    try {
      let result = await userManagementManager.getLookupData(
        value,
        "only_investor_users"
      );
      result = result?.results?.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));

      setOption2(result);
    } catch (err: any) {
      toast.error(err?.detail);
    }
  };

  const handleTextChange3 = async () => {
    try {
      let result = await userManagementManager.getLookupData(
        "",
        "select_user_hashtags"
      );
      result = result?.private_hashtags.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));

      setOption3(result);
    } catch (err: any) {
      toast.error(err?.detail);
    }
  };

  const onSelect1 = () => {
    setOption1([]);
    setSelectedVal2({});
    const elem = ref2?.current?.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    const inputElem = ref2?.current?.getElementsByClassName(
      "MuiOutlinedInput-input"
    )[0];

    if (elem) elem.click();
    if (inputElem) inputElem.blur();
  };

  const onSelect2 = () => {
    setOption2([]);
    setSelectedVal1("");
    const elem = ref1?.current?.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    const inputElem = ref1?.current?.getElementsByClassName(
      "MuiOutlinedInput-input"
    )[0];

    if (elem) {
      elem.click();
    }
    if (inputElem) {
      inputElem.blur();
    }
  };

  useEffect(() => {
    handleTextChange3();
  }, []);

  const handleAngelSearch = async () => {
    try {
      const result = await userManagementManager.getLookupData(
        "",
        "select_user_hashtags",
        selectedVal3?.value
      );
      setAngelData(result?.hashtag_users);
    } catch (e) {
      toast.error("Something Went Wrong");
    }
  };

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div>
            <Breadcrumb
              steps={breadCrumbData?.steps}
              pageHeader={breadCrumbData.pageHeader}
              stepKey={breadCrumbData.stepKey}
            />
          </div>
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Typography variant="h5" textAlign="left">
            Select User
          </Typography>
          <Divider />
          <div style={{ marginTop: "30px" }}>
            <>
              <LvAutoComplete
                ref={ref1}
                label="All Approved Users"
                options={option1}
                setOptions={setOption1}
                selectedObject={selectedVal1}
                setSelectedObject={setSelectedVal1}
                onChange={handleTextChange1}
                onSelect={onSelect1}
              />

              <br />

              <LvAutoComplete
                label="Only Investor Users"
                ref={ref2}
                options={option2}
                setOptions={setOption2}
                selectedObject={selectedVal2}
                setSelectedObject={setSelectedVal2}
                onChange={handleTextChange2}
                onSelect={onSelect2}
              />

              <br />

              <Link
                // aria-disabled={!selectedVal1?.value && !selectedVal2?.value}
                // style={{
                //   pointerEvents:
                //     !selectedVal1?.value && !selectedVal2?.value
                //       ? "none"
                //       : "auto",
                // }}
                style={{
                  marginLeft: "auto",
                  display: "block",
                  width: "fit-content",
                }}
                to={`/user/show-user/${
                  selectedVal1?.value
                    ? selectedVal1?.value
                    : selectedVal2?.value
                    ? selectedVal2.value
                    : "4"
                }/`}
              >
                <ActionButton
                // disabled={!selectedVal1?.value && !selectedVal2?.value}
                >
                  Go to User&apos;s Panel
                </ActionButton>
              </Link>
            </>
          </div>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={6} paddingRight={2}>
          <Typography variant="h5" textAlign="left">
            Search Angels with Admin Hashtags
          </Typography>
          <Divider />
          <div style={{ marginTop: "30px" }}>
            <Formik initialValues={{ hashtag: "" }} onSubmit={() => {}}>
              <>
                <LvAutoComplete
                  label="Choose Hashtag"
                  options={option3}
                  setOptions={setOption3}
                  selectedObject={selectedVal3}
                  setSelectedObject={setSelectedVal3}
                />

                <br />
                <ActionButton
                  disabled={!selectedVal3?.value}
                  onClick={handleAngelSearch}
                  badgeStyles={{
                    marginLeft: "auto",
                    display: "block",
                    width: "fit-content",
                  }}
                >
                  Search
                </ActionButton>
              </>
            </Formik>
          </div>
          <br />
          {Array.isArray(angelData) && angelData?.length ? (
            <>
              {" "}
              <Typography fontSize={16}>
                Results for Angels with Hashtag <b> {selectedVal3?.label} </b>
              </Typography>
              <br />
              <Grid container>
                <Grid item xs={4}>
                  <b> User Name </b>
                </Grid>
                <Grid item xs={8}>
                  <b> Private Hashtags </b>
                </Grid>
                {angelData.map((item: any) => (
                  <>
                    <Grid item xs={4}>
                      <Link to={`/user/show_user/${item?.id}/`}>
                        {item?.name}
                      </Link>
                    </Grid>
                    <Grid item xs={8}>
                      {item?.hashtags}
                    </Grid>
                  </>
                ))}
              </Grid>{" "}
            </>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
}
