import { useState } from "react";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import CaDocStatusTable from "./CaDocStatusTable";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { Box, Grid } from "@mui/material";

export default function CaDocStatus() {
  const [selectedTab, setSelectedTab] = useState("lv_ca_sent");
  const [value, setValue] = useState("");

  const tabs = [
    {
      name: "CA Sent",
      value: "lv_ca_sent",
      content: <CaDocStatusTable status={selectedTab} />,
    },
    {
      name: "LV Investor Signed",
      value: "lv_investor_signed",
      content: <CaDocStatusTable status={selectedTab} />,
    },
    {
      name: "LV Trustee Signed",
      value: "lv_trustee_signed",
      content: <CaDocStatusTable status={selectedTab} />,
    },
    {
      name: "LV Authorized Signed",
      value: "lv_authorized_signed",
      content: <CaDocStatusTable status={selectedTab} />,
    },
  ];

  const handleTabChange = (tab: any) => {
    setSelectedTab(tab);
  };

  const breadCrumbData = {
    stepKey: 1,
    steps: [
      {
        title: "AIF Management",
        titleDisplay: false,
      },
    ],
    pageHeader: {
      header: "CA Doc Status",
      navigationButton: false,
    },
  };

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>

      <HorizontalTabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={handleTabChange}
        xAxisPadding={false}
        customTabsStyles={{
          width: "100%",
          height: "44px",
        }}
        customTabStyles={{
          fontFamily: "Work Sans",
          lineHeight: "21px",
          textAlign: "center",
          letterSpacing: "0.5px",
          fontStyle: "normal",
        }}
      />
    </Box>
  );
}
