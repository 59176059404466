import { Box, Divider, Grid, Typography } from "@mui/material";
import { Breadcrumb } from "../../../../../ui-library/navs/breadcrumb";
import React, { useState } from "react";
import { breadCrumbDataAnalytics } from "../../../constants/mailCentralConstant/MailCentralConstant";
import { useNavigate } from "react-router-dom";
import { FileDownload } from "@mui/icons-material";
import { buttonStyle } from "./styles";
import LvAutoComplete from "../../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../../ui-library/buttons/ActionButton";

const MailerAnalytics = () => {
  const navigate = useNavigate();
  const navigationFunction = () => navigate(-1);
  const [selectedCampaign, setSelectedCampaign] = useState<any>({});
  const [campaignOptions, setCampaignOptions] = useState<any>([]);

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={4}>
          <Breadcrumb
            steps={breadCrumbDataAnalytics?.steps}
            pageHeader={{
              ...breadCrumbDataAnalytics?.pageHeader,
              navigationFunction,
            }}
            stepKey={breadCrumbDataAnalytics?.stepKey}
            breadcrumbSeparator={breadCrumbDataAnalytics?.breadcrumbSeparator}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container>
        <Grid item xs={6}>
          <LvAutoComplete
            label="Select Campaign"
            options={campaignOptions}
            setOptions={setCampaignOptions}
            selectedObject={selectedCampaign}
            setSelectedObject={setSelectedCampaign}
            onSelect={() => setCampaignOptions([])}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container rowGap={2}>
        <Grid item container xs={6}>
          {/* summary */}
          <Grid item xs={12}>
            <Typography variant="h6" textAlign="left">
              Summary
            </Typography>
          </Grid>
          <br />
          <br />
          <Grid item xs={12}>
            <Divider />
            <br />
          </Grid>
          <Grid item xs={6}>
            <Typography>Total Mail Count:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Total Mail Sent:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Open Mail Count:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Click Count:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Progress:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>40%</Typography>
          </Grid>
          <br />
          <br />
          <br />
          {/* Detailed Report */}
          <Grid item xs={12}>
            <Typography variant="h6" textAlign="left">
              Detailed Report
            </Typography>
          </Grid>
          <br /> <br />
          <Grid item xs={12}>
            <Divider />
            <br />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold" }} textAlign="left">
              Non Digest
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Mail Count:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Opened Users Count:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Clicked Users Count:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={12}>
            <br />
            <Typography sx={{ fontWeight: "bold" }} textAlign="left">
              Daily Digest
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Sent Mail Count:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Opened Users Count:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Clicked Users Count: </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={12}>
            <br />
            <Typography sx={{ fontWeight: "bold" }} textAlign="left">
              Weekly Digest
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Sent Mail Count:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Opened Users Count:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>Clicked Users Count:</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>4</Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <ActionButton badgeStyles={buttonStyle}>
            <FileDownload /> Report for Yes/No click's
          </ActionButton>
          <ActionButton badgeStyles={buttonStyle}>
            <FileDownload /> Report for All click's
          </ActionButton>
          <ActionButton badgeStyles={buttonStyle}>
            <FileDownload /> Get report for Opened Emails
          </ActionButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MailerAnalytics;
