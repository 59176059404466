import { Box, Grid } from "@mui/material";
import { HorizontalTabs } from "../../../../ui-library/tabs/horizontal-tabs";
import { useState } from "react";
import StartCaGeneration from "./StartCaGeneration";
import CAApprovalProcess from "./CAApprovalProcess";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { breadCrumbDataCADocManagement } from "../../constants/CADocConstants";

export default function CaDocaManagement() {
  const tabs: any = [
    {
      name: "Start CA Generation",
      value: "start_ca_generation",
    },
    {
      name: "CA Approval Process",
      value: "ca_approval_process",
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabs[0].value);

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={4}>
          <Breadcrumb
            steps={breadCrumbDataCADocManagement?.steps}
            pageHeader={breadCrumbDataCADocManagement.pageHeader}
            stepKey={breadCrumbDataCADocManagement.stepKey}
          />
        </Grid>
      </Grid>
      <HorizontalTabs
        tabs={tabs}
        selectedTab={selectedTab}
        onTabChange={(tab: any) => {
          setSelectedTab(tab);
        }}
        scrollable={false}
      />
      {selectedTab === "start_ca_generation" ? <StartCaGeneration /> : null}
      {selectedTab === "ca_approval_process" ? <CAApprovalProcess /> : null}
    </Box>
  );
}
