import { Box, Grid } from "@mui/material";
import { CoreDropdown } from "../../../../ui-library/dropdowns/core-dropdown";
import { FileUpload } from "../../../../ui-library/form-elements/file-upload/FileUpload";
import { CustomTextField } from "../../../../ui-library/form-elements/CustomTextField";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { useState } from "react";
import { toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";
import SyndicateManagement from "../../managers/syndicateManagementManager";
import { useStyles } from "../../../userManagement/components/usersPanel/styles";
import UserManagementManager from "../../../userManagement/managers/userManagementManager";
import {
  STARTUP_STAGES,
  bussinessTypeOptions,
  startupStageOptions,
} from "../../../../constants/sartupConstants";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";
import { ActionButton } from "../../../../ui-library/buttons/ActionButton";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Syndicate Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Create Startup",
    navigationButton: false,
  },
};
export default function CreateStartup() {
  const authUser = useContext(AuthContext);
  const syndicateManagementManager = new SyndicateManagement(authUser);
  const userManagementManager = new UserManagementManager(authUser);
  const classes = useStyles();
  const [creating, setCreating] = useState(false);
  const [name, setName] = useState("");
  const [stage, setStage] = useState<any>();

  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [locationOptions, setLocationOptions] = useState<any>([]);
  const getLocationOptions = async (search: string) => {
    try {
      let result = await userManagementManager.getLookupData(
        search,
        "locations"
      );
      result = result?.results.map((item: any) => ({
        label: item.text,
        value: item.id,
      }));
      setLocationOptions(result);
    } catch (e: any) {
      toast.error(e);
    }
  };

  const [permaLink, setPermaLink] = useState("");
  const [bussinessType, setBussinessType] = useState<any>();
  const [summary, setSummary] = useState("");
  const [twitterPitch, setTwitterPitch] = useState("");
  const [raiseAmount, setRaiseAmount] = useState(0);
  const [file, setFile] = useState([]);

  const reset = () => {
    setName("");
    setStage({});
    setSelectedLocation({});
    setLocationOptions([]);
    setPermaLink("");
    setBussinessType({});
    setSummary("");
    setFile([]);
    setRaiseAmount(0);
    setTwitterPitch("");
  };

  const createStartup = async () => {
    try {
      setCreating(true);
      const result = await syndicateManagementManager.createStartup(
        name,
        STARTUP_STAGES[stage?.value] ?? 0,
        selectedLocation?.value ?? "",
        permaLink,
        bussinessType?.value ?? "",
        summary,
        file[0],
        raiseAmount,
        twitterPitch
      );
      setCreating(false);
      reset();
      toast.success(result?.message);
    } catch (e: any) {
      setCreating(false);
      toast.error(e?.detail);
    }
  };

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <div>
            <Breadcrumb
              steps={breadCrumbData?.steps}
              pageHeader={breadCrumbData.pageHeader}
              stepKey={breadCrumbData.stepKey}
            />
          </div>
        </Grid>
      </Grid>
      <br />

      <Grid container spacing={2}>
        <Grid item xs={3}>
          Startup Name
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            value={name}
            onChange={(val: string) => setName(val)}
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          Stage
        </Grid>
        <Grid item xs={6}>
          <CoreDropdown
            label="Startup Stage"
            options={startupStageOptions}
            value={stage}
            onClickMenuItem={(option: any) => setStage(option)}
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          Location
        </Grid>
        <Grid item xs={6}>
          <Box width="100%">
            <LvAutoComplete
              label={"Choose or enter a new city"}
              options={locationOptions}
              setOptions={setLocationOptions}
              selectedObject={selectedLocation}
              setSelectedObject={setSelectedLocation}
              onSelect={() => setLocationOptions([])}
              onChange={getLocationOptions}
            />
          </Box>
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          Permalink
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            value={permaLink}
            onChange={(val: string) => setPermaLink(val)}
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          Type of Business
        </Grid>
        <Grid item xs={6}>
          <CoreDropdown
            label="Type of Bussiness"
            options={bussinessTypeOptions}
            value={bussinessType}
            onClickMenuItem={(options: any) => setBussinessType(options)}
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          Product/Service Summary
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            value={summary}
            onChange={(val: string) => setSummary(val)}
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          Twitter Pitch
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            value={twitterPitch}
            onChange={(val: string) => setTwitterPitch(val)}
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          What is the amount you want to raise?
        </Grid>
        <Grid item xs={6}>
          <CustomTextField
            value={raiseAmount}
            onChange={(val: number) => setRaiseAmount(val)}
            type="number"
          />
        </Grid>
        <Grid item xs={3} />

        <Grid item xs={3}>
          Pitch Deck
        </Grid>
        <Grid item xs={6}>
          <FileUpload
            customStyles={{ maxWidth: 550 }}
            placeholder="select a file"
            helperText="upload your file here"
            selectedFile={(file: any) => setFile(file)}
            multiple
            value={file}
            accept={{
              "image/svg": [".png", ".svg"],
              "text/pdf": [".pdf"],
            }}
            title="File Upload Dialog Box"
            hideDocumentVault
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={9} display="flex" justifyContent="end">
          <ActionButton
            onClick={createStartup}
            isLoading={creating}
            disabled={
              creating ||
              !name?.trim() ||
              !stage?.value ||
              !permaLink.trim() ||
              !bussinessType?.value ||
              !summary.trim() ||
              !raiseAmount ||
              !twitterPitch.trim()
            }
          >
            Create Startup
          </ActionButton>
        </Grid>
      </Grid>
    </Box>
  );
}
