import { API_STATUS } from "../../../../constants/commonConstants";
import CommitmentManagementManager from "../../managers/commitmentManagementManager";
import { isEmpty } from "../../../../utils/commonUtils";
import { getFormattedDate } from "../../../../utils/dateUtils";
import { Box, Grid } from "@mui/material";
import { Breadcrumb } from "../../../../ui-library/navs/breadcrumb";
import { TablePlaceholder } from "../../../../ui-library/placeholders/table-placeholder/TablePlaceholder";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const breadCrumbData = {
  stepKey: 1,
  steps: [
    {
      title: "Commitment Management",
      titleDisplay: false,
    },
  ],
  pageHeader: {
    header: "Commitment Withdraw Information Management",
    navigationButton: false,
  },
};

export default function CommitWithdrawInfo() {
  const commitmentManager = new CommitmentManagementManager();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  let columns = [
    {
      field: "investor_name",
      headerName: "Investor Name",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "email",
      headerName: "Investor Email",
      flex: 3,
      minWidth: 150,
    },
    {
      field: "startup_name",
      headerName: "Startup Name",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "withdrawed_on",
      headerName: "Withdraw Date",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "withdrawal_reason",
      headerName: "Reason (If Any)",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "profile",
      headerName: "View Profile",
      flex: 2,
      minWidth: 150,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link to={`/user/show_user/${params.row?.user_id}/`}>
            {params?.row?.investor_name}{" "}
          </Link>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const getData = async () => {
    try {
      setApiStatus(API_STATUS.LOADING);
      const { results, count } = await commitmentManager.commitWithdrawnInfo(
        page
      );
      setCount(count);
      setFormattedData(
        results?.map((item: any) => ({
          ...item,
          withdrawed_on: getFormattedDate(item?.withdrawed_on),
        }))
      );
      setApiStatus(API_STATUS.SUCCESS);
    } catch (e) {
      console.log(e);
      setApiStatus(API_STATUS.ERROR);
    }
  };

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <Box p={2}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Breadcrumb
            steps={breadCrumbData?.steps}
            pageHeader={breadCrumbData.pageHeader}
            stepKey={breadCrumbData.stepKey}
          />
        </Grid>
      </Grid>

      {apiStatus == API_STATUS.LOADING ? (
        <TablePlaceholder
          rows={6}
          columns={6}
          rowHeight="40px"
          columnHeaderHeight="40px"
        />
      ) : (
        <>
          <LvComplexTable
            rows={formattedData}
            columns={columns}
            rowHeight={40}
            headerHeight={50}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty(formattedData)}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
            defaultSortColumn={{ field: "withdrawed_on", sort: "desc" }}
          />
        </>
      )}
    </Box>
  );
}
