import { Typography, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Topbar } from "../../ui-library/navs/topbar";
// import CustomFooter from '../customFooter';
import useStyles from "./styles";
import { ActionButton } from "../../ui-library/buttons/ActionButton";

const PageNotFound = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div>
      <div className={classes.mainBody}>
        <Topbar position="relative">
          <img
            src="https://dsjvxb1plg419.cloudfront.net/trica-equity-logo-01.svg"
            alt="trica"
            style={{ width: "75px", height: "auto" }}
          />
        </Topbar>
        <div className={classes.container}>
          <Grid container>
            <Grid xs={12} sm={4} className={classes.gridContainer}>
              <Typography className={classes.description}>
                Page not found...
              </Typography>
              <Typography className={classes.subDescription}>
                Sorry, the page you are looking for doesn&apos;t exist
              </Typography>
              <ActionButton
                onClick={() => {
                  navigate("/");
                }}
              >
                BACK TO DASHBOARD
              </ActionButton>
            </Grid>
            {/* <Grid xs={12} sm={8} className={classes.gridContainer}>
              <img src={IconConstant.PAGE_NOT_FOUND} alt="page not found" />
            </Grid> */}
          </Grid>
        </div>
      </div>
      {/* <CustomFooter /> */}
    </div>
  );
};
export default PageNotFound;
