import { Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";

import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { API_STATUS } from "../../../../constants/commonConstants";
import { OutlinedButton } from "../../../../ui-library/buttons/OutlinedButton";
import { getColor } from "../../../../ui-library/Styles/Colors";
import LvAutoComplete from "../../../../ui-library/autoComplete/LvAutoComplete";

const StartupSchemeProgress = () => {
  const columns1 = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "lead_investors",
      headerName: "Lead Investors",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "sectors",
      headerName: "Secotors",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "stage",
      headerName: "Stage",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "market",
      headerName: "Market",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "founded",
      headerName: "Founded",
      flex: 1,
      minWidth: 100,
    },
  ];

  const columns2 = [
    {
      field: "name",
      headerName: "Investor Name",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "investor_type",
      headerName: "Investor Type",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "is_lead",
      headerName: "Is Lead",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "document_type",
      headerName: "Scheme Document Type",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "action",
      headerName: "View Document",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <OutlinedButton
            customStyles={{
              color: getColor("action_button", 200),
              borderColor: getColor("action_button", 200),
            }}
            // onClick={() => handleViewClick()}
          >
            Approve/Reject User
          </OutlinedButton>
        );
      },
    },
  ];

  const [selectedVal, setSelectedVal] = useState<any>({});
  const [option, setOption] = useState<any>([]);

  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  const [formattedData, setFormattedData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(20);

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <>
          <Stack direction="row" gap={3} mb={3}>
            <Grid item xs={4}>
              <Typography>Select Startup</Typography>
            </Grid>
            <Grid item xs={8}>
              <LvAutoComplete
                label="Select Startup"
                options={option}
                setOptions={setOption}
                selectedObject={selectedVal}
                setSelectedObject={setSelectedVal}
                // onChange={handleTextChange}
              />
            </Grid>
          </Stack>
          <Typography sx={{ fontWeight: "bold", mb: 2 }}>
            Startup Details
          </Typography>
          <LvComplexTable
            rows={[]}
            columns={columns1}
            rowHeight={40}
            headerHeight={40}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty([])}
            enableAutoHeight={true}
            pageSize={10}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />

          <br />

          <LvComplexTable
            rows={[]}
            columns={columns2}
            rowHeight={40}
            headerHeight={60}
            minWidth={750}
            enableAdvancedFiltering
            isUnFilteredDataPresent={!isEmpty([])}
            enableAutoHeight={true}
            pageSize={20}
            page={page}
            enablePagination
            totalRowCount={count}
            handlePageChange={handlePageChange}
            paginationMode="server"
          />
        </>
      </Grid>
    </Grid>
  );
};

export default StartupSchemeProgress;
