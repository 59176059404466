import React, { FC, useState } from "react";
import { LvComplexTable } from "../../../../ui-library/tables/lv-complex-table";
import { isEmpty } from "../../../../utils/commonUtils";
import { Link } from "react-router-dom";
import VerifyModal from "../modals/VerifyModal";
import TextButton from "../../../../ui-library/buttons/textButton/TextButton";

export type AwaitingPhoneNumberType = {
  formattedData: any[];
  page: number;
  setPage: (num: number) => void;
  count: number;
};

const AwaitingPhoneNumber: FC<AwaitingPhoneNumberType> = (
  props: AwaitingPhoneNumberType
) => {
  const { setPage, formattedData, page, count } = props;
  const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);

  const [selected, setSelected] = useState<any>();

  const columns = [
    {
      field: "date",
      headerName: "Date",
      align: "center",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      flex: 2,
      minWidth: 200,
      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <Link to={`/user/show-user/${params.row?.id}/`}>
            {params?.row?.name}{" "}
          </Link>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerAlign: "left",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "mobile",
      headerName: "Phone No.",
      align: "center",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "type",
      headerName: "Type",
      headerAlign: "left",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "startup",
      headerName: "Startup",
      headerAlign: "left",
      flex: 2,
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      align: "center",
      flex: 2,
      minWidth: 150,

      renderCell: (params: any) => {
        const actionData = params.row.action;
        return (
          <TextButton
            onClick={() => {
              setSelected(params.row);
              setIsVerifyModalOpen(true);
            }}
          >
            Mark Verified
          </TextButton>
        );
      },
    },
  ];

  const handlePageChange = (page: any) => {
    setPage(page + 1);
  };

  const handleCloseVerifyModal = () => {
    setIsVerifyModalOpen(false);
  };
  const handleOpenVerifyModal = () => {
    setIsVerifyModalOpen(true);
  };

  return (
    <div style={{ overflow: "auto" }}>
      <LvComplexTable
        rows={formattedData}
        columns={columns}
        rowHeight={40}
        headerHeight={40}
        minWidth={750}
        enableAdvancedFiltering
        isUnFilteredDataPresent={!isEmpty(formattedData)}
        enableAutoHeight={true}
        pageSize={20}
        page={page}
        enablePagination
        totalRowCount={count}
        handlePageChange={handlePageChange}
        paginationMode="server"
      />
      <VerifyModal
        isOpen={isVerifyModalOpen}
        handleClose={handleCloseVerifyModal}
        selected={selected}
      />
    </div>
  );
};

export default AwaitingPhoneNumber;
